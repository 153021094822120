<template>
  <div>
    <vx-card :is-refresh="isLoading">
      <div class="vx-row">
        <!---HEADER-->
        <div class="vx-col w-full md:w-6/12">
          <h2 class="font-bold">#{{ initData.header.no_po }}</h2>
          <p class="text-md mt-1">{{ initData.header.nama_proyek }}</p>
          <p class="flex items-center flex-wrap mt-1">
            <span class="whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full font-bold bg-primary">
              Jenis PO: {{ initData.header.jenis_po }}
            </span>
            <span class="ml-2 whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full font-bold" :class="['bg-' + initData.header.status_approval_color]">
              Status Approval: {{ initData.header.status_approval }}
            </span>
          </p>
          <vs-divider/>
          <div class="mt-2">
            <h6>Rekanan:</h6>
            <div class="flex items-center mt-1">
              <vs-icon size="small" icon-pack="material-icons" icon="business"/>
              <p class="text-lg font-semibold ml-1">{{ initData.header.nama_rekanan }}</p>
            </div>
          </div>
          <table class="border-collapse w-full mt-5">
            <tr>
              <td class="p-1 border border-solid d-theme-border-grey-light">Nilai Total</td>
              <td class="p-1 border border-solid d-theme-border-grey-light text-right">{{ initData.header.nilai_total | idr }}</td>
            </tr>
            <tr>
              <td class="p-1 border border-solid d-theme-border-grey-light">DPP</td>
              <td class="p-1 border border-solid d-theme-border-grey-light text-right">{{ initData.header.dpp | idr }}</td>
            </tr>
            <tr>
              <td class="p-1 border border-solid d-theme-border-grey-light">PPN</td>
              <td class="p-1 border border-solid d-theme-border-grey-light text-right">{{ initData.header.ppn_jml | idr }}</td>
            </tr>
            <tr>
              <td class="p-1 border border-solid d-theme-border-grey-light">PPh</td>
              <td class="p-1 border border-solid d-theme-border-grey-light text-right">{{ initData.header.pph_jml | idr }}</td>
            </tr>
          </table>
          <vs-divider/>
          <div class="flex mt-2 opacity-75">
            <div class="flex items-center">
              <vs-icon icon-pack="feather" icon="icon-user"/>
              <p class="text-sm ml-1">Created By: {{ initData.header.username }}</p>
            </div>
          </div>
          <div class="flex mt-2 opacity-75">
            <div class="flex items-center">
              <vs-icon icon-pack="feather" icon="icon-calendar"/>
              <p class="text-sm ml-1">Created At: {{ initData.header.created_at }}</p>
            </div>
            <div class="flex items-center ml-3">
              <vs-icon icon-pack="feather" icon="icon-clock"/>
              <p class="text-sm ml-1">Last Update: {{ initData.header.updated_at }}</p>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-start">
                <vs-button class="mr-4 px-4" color="primary" type="border" icon-pack="feather" icon="icon-edit" v-if="initData.header.status_approval !== 'DITERIMA'" :to="{ name: 'proyek.po.edit', params: { idPo: $route.params.idPo } }">Edit</vs-button>
                <vs-button class="mr-4 px-3" color="danger" type="border" icon-pack="feather" icon="icon-trash-2" v-if="initData.header.status_approval !== 'DITERIMA'" @click="confirmDelete($route.params.idPo)">Hapus</vs-button>
                <vs-button color="primary" type="filled" class="px-3 mr-3" icon-pack="feather" icon="icon-printer" v-if="initData.header.status_approval === 'DITERIMA'" @click="print">Print</vs-button>
                <vs-button color="primary" type="flat" class="mr-4 px-3" icon-pack="feather" icon="icon-refresh-cw" @click="getInitData">Refresh</vs-button>
              </div>
            </div>
          </div>
        </div>

        <!---BARANG/JASA-->
        <div class="vx-col md:w-6/12 w-full mt-8 md:mt-0">
          <h6 class="mb-2">ITEM {{ initData.header.jenis_po }}</h6>
          <div class="flex border d-theme-dark-bg items-center border border-solid d-theme-border-grey-light border-t border-b-0">
            <vs-input v-model="search" icon-no-border icon="icon-search" icon-pack="feather" placeholder="Cari Item" class="vs-input-no-border rounded-none vs-input-no-shdow-focus w-full"/>
          </div>
          <div class="h-screen-60 overflow-y-scroll border border-solid d-theme-border-grey-light border-t">
            <div v-for="(item, index) in itemsBarang" :key="item.id" class="email__mail-item border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b border-l-0">
              <div class="mail__mail-item sm:px-4 px-2 py-4 mail__opened-mail">
                <div class="flex space-x-2 w-full">
                  <vs-avatar class="sender__avatar flex-shrink-0" :text="(index + 1).toString()" size="30px"/>
                  <div class="flex w-full sm:w-12/12">
                    <div class="">
                      <p class="text-sm font-medium">{{ item.nama_item_pengadaan }}</p>
                      <span class="text-xs bg-ema">Harga: {{ item.harga_satuan }}</span>
                      <span class="text-xs bg-ema ml-3">|</span>
                      <span class="text-xs bg-ema ml-3">Qty: {{ item.qty }}</span>
                      <span class="text-xs bg-ema ml-3">|</span>
                      <span class="text-xs bg-ema ml-3">Satuan: {{ item.satuan_item_pengadaan }}</span>
                      <br>
                      <span class="text-xs opacity-75">{{ item.keterangan }}</span>
                    </div>
                  </div>
                  <a :href="item.file_pr_url" target="_blank" v-if="item.file_pr_url">
                    <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <!--kontrak approval progress-->
    <vx-card :is-refresh="isLoading" collapse-action class="mt-5" title="Kontrak Approval Progress">
      <ApprovalProgress
        ref="approvalProgress"
        type="PO-KONTRAK"
        :id-ref="$route.params.idPo"/>
    </vx-card>

    <!---PEMBAYARAN-->
    <vx-card class="mt-5" title="Pembayaran" collapse-action>
      <vs-table :data="initData.pembayaran" stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap text-center">Action</vs-th>
          <vs-th class="whitespace-no-wrap text-center">No. Termin</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Tgl Due</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Status</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Persen Bayar</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Jumlah</vs-th>
          <vs-th class="whitespace-no-wrap text-center">DPP</vs-th>
          <vs-th class="whitespace-no-wrap text-center">PPN</vs-th>
          <vs-th class="whitespace-no-wrap text-center">PPh</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Progress Approval</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Jumlah Terbayar</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="item in data" :key="item.id">
            <vs-td>
              <div class="flex">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button class="py-2 px-3" size="small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item class="p-1" :disabled="initData.header.status_approval !== 'DITERIMA' || item.status !== 'UNPAID'" @click="confirmClaimTerminPayment(item)"><span class="whitespace-no-wrap">Claim Payment</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_termin }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1" :class="'bg-' + item.status_color">
                  <span class="text-xs">{{ item.status }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.persen | idr }}%</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_total | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.dpp | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.ppn_jml | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span v-if="!item.status_approval">-</span>
              <span v-else class="underline cursor-pointer" @click="showModalApproval(item)">Lihat Progress</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_bayar | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.keterangan || '-' }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <!---FILES-->
    <vx-card class="mt-5" title="File Pengajuan Pembayaran Termin dari Rekanan" collapse-action>
      <vs-table :data="initData.files" stripe class="fix-table-input-background">
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">No Termin</vs-th>
          <vs-th class="whitespace-no-wrap">Nama File</vs-th>
          <vs-th class="whitespace-no-wrap">Lihat File</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>Termin {{ item.no_termin }}</vs-td>
            <vs-td>{{ item.nama }}</vs-td>
            <vs-td>
              <a :href="item.file_url" target="_blank">
                <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <!--modals-->
    <ClaimPayment :isActive.sync="modalClaimPayment.active" :termin="modalClaimPayment.termin" @success="onSuccessClaimPayment"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
  </div>
</template>

<script>
import PoRepository from '@/repositories/proyek/po-repository'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'PoShow',
  components: {
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress'),
    ClaimPayment: () => import('@/views/pages/proyek/po/parts/ClaimPayment'),
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress')
  },
  mixins: [printoutMixin],
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      search: null,
      isLoading: false,
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      modalClaimPayment: {
        termin: {},
        active: false
      },
      initData: {
        header: {},
        barang: [],
        pembayaran: [],
        files: []
      }
    }
  },
  computed: {
    itemsBarang () {
      const search = this.search
      const items = this.initData.barang
      if (search != null) {
        return _.filter(items, (item) => { return item.nama_item_pengadaan.toLowerCase().includes(search) })
      }
      return items
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return { maxScrollbarLength: 100, wheelSpeed: 0.70 }
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idPo = this.$route.params.idPo
      PoRepository.show(idPo)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showModalApproval (item) {
      this.modalApproval.type = 'PO-TERMIN'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    confirmClaimTerminPayment (termin) {
      this.modalClaimPayment.termin = termin
      this.modalClaimPayment.active = true
    },

    onSuccessClaimPayment () {
      this.getInitData()
      this.emitUpdated()
      this.modalClaimPayment.active = false
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini ?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    delete (id) {
      PoRepository.delete(id)
        .then(response => {
          this.notifySuccess('Data berhasil terhapus')
          this.$router.push({ name: 'proyek.po' })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },
    print () {
      const id = this.$route.params.idPo
      this.showPrintOutFile('PURCHASE_ORDER', { ids: [id] })
    }
  }
}
</script>
